<template>
  <div class="visions">
    <vs-card>
      <header class="d-flex justify-content-between">
        <h4
          class="mb-0 ml-2 text-uppercase"
          :style="{ color: colorSecondary }"
        >
          Minhas Visões
        </h4>

        <!-- Toggle Display Settings -->
        <vs-button
          icon="settings"
          @click="openConfigurationPopup = true"
        >
          Gerenciar Visões
        </vs-button>
      </header>

      <hr class="custom-hr">

      <!-- Vision Cards -->
      <vs-row
        :key="visionsRenderKey"
        class="visions mt-2 py-2"
      >
        <vs-col
          v-for="(vision, vidx) in visibleVisions"
          :key="vidx"
          vs-lg="4"
        >
          <vs-card class="vision-card">
            <header
              slot="header"
              class="d-flex align-items-center justify-content-between"
            >
              <h5
                class="text-uppercase mr-1"
                :style="{
                  fontSize: vision.name.length < 50 ? '16px' : '14px',
                  whiteSpace: 'pre-line',
                }"
              >
                {{ vision.name }}
              </h5>

              <div class="d-flex align-items-center">
                <i
                  class="mdi mdi-account text-dark mr-1"
                  :style="{ fontSize: '1.5em' }"
                />

                <h3 :style="{ color: colorSecondary }">
                  {{ vision.count | formatLocale }}
                </h3>
              </div>
            </header>

            <main class="d-flex flex-column pb-3">
              <div class="d-flex align-items-center text-dark mb-3">
                <span
                  class="material-icons mr-1 mb-1"
                  :style="{ fontSize: '1em' }"
                >
                  filter_list_alt
                </span>

                <span>Opções do filtro:</span>
              </div>

              <div class="indicators-list">
                <vs-tooltip
                  v-for="(option, oidx) in vision.filterOptions"
                  :key="oidx"
                  :text="`Respostas: ${option.values.join(', ')}`"
                >
                  <vs-chip
                    :key="vidx"
                    color="dark"
                    class="mr-1"
                  >
                    {{ option.indicatorName }}
                  </vs-chip>
                </vs-tooltip>
              </div>
            </main>

            <footer class="mx-1">
              <vs-button
                class="btn-block shadow-sm"
                color="secondary"
                icon="visibility"
                type="line"
                @click="() => goToVision(vision.name, vision.uuid)"
              >
                Visualizar
              </vs-button>
            </footer>
          </vs-card>
        </vs-col>
      </vs-row>
    </vs-card>

    <!-- Display Settings Popup -->
    <vs-popup
      title="Gerenciar Exibição"
      :active.sync="openConfigurationPopup"
    >
      <div
        v-if="filterVisions && filterVisions.length"
        class="text-center"
      >
        <!-- Filter Visions -->
        <div class="filter-visions">
          <ul class="list-group c-list-group">
            <li
              v-for="(filter, filterIdx) in filterVisions"
              :key="filterIdx"
              class="list-group-item d-flex align-items-center justify-content-between"
            >
              <p class="mr-2 mb-0 text-left w-50">
                {{ filter.name }}: <b>{{ filter.count }}</b>
              </p>

              <vs-switch
                :checked="filter.isVisible"
                color="secondary"
                vs-icon="visibility"
                @click="updateFilterVisibility(filter.filterId, !filter.isVisible)"
              />
            </li>
          </ul>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
/* Services */
// import Fan from '@/services/fan';
import Filter from '@/services/filter';

// const fanService = new Fan();
const filterService = new Filter();

export default {
  name: 'Visions',
  data: () => ({
    filterVisions: [],
    openConfigurationPopup: false,
    visionsRenderKey: 0,
  }),
  computed: {
    /* Store */
    ownerId() {
      return this.$store.state.ownerID;
    },
    /* Styles */
    colorSecondary() {
      return this.$store.state.configBox.cor2;
    },
    /* Visions */
    visibleVisions() {
      if (!this.filterVisions) return [];

      const filtered = this.filterVisions.filter((el) => el.isVisible == true);

      return filtered.map((vision) => {
        const groupedFilters = vision.filterOptions.reduce((acc, obj) => {
          const key = obj.indicatorName;
          const existingGroup = acc.find(group => group.indicatorName === key);

          if (existingGroup) {
            existingGroup.values.push(obj.value);
          } else {
            acc.push({ indicatorName: key, values: [obj.value] });
          }

          return acc;
        }, []);

        return {
          ...vision,
          filterOptions: groupedFilters,
        };
      });
    },
  },
  mounted() {
    this.getFilterVisions();
  },
  methods: {
    /* API */
    getFilterVisions() {
      this.$store.dispatch('set_isLoadingActive', true);

      filterService.getFilterVisions(this.ownerId)
        .then((resp) => (this.filterVisions = resp))
        .catch((err) => alert(err))
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    updateFilterVisibility(filterId, status){
      this.$store.dispatch('set_isLoadingActive', true);

      filterService.updateFilterVisibility(filterId, status)
        .then(() => this.getFilterVisions())
        .catch((err) => alert(err))
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    /* Router */
    goToVision(filterName, filterUid) {
      this.$store.state.filterName = filterName;
      this.$store.dispatch('setFilterUid', filterUid);
      this.$router.push('/dashboards/vision-details');
    },
  },
};
</script>

<style lang="scss" scoped>
.visions {
  color: #2d3142;
}

/* Visions */
.visions > .vs-col {
  padding: 0 0.5rem 0.5rem;
}

/* Vision Card */
.vision-card {
  background-color: white;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}

.vision-card > header span,
.vision-card > header i {
  font-size: 1.5rem;
}

.vision-card > main {
  display: flex;
  flex-flow: column nowrap;
  gap: .3rem;
  height: 100%;
  overflow: auto;

  .indicators-list {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    max-height: 62px;
    overflow: auto;
  }
}

.filter-visions {
  max-height: 500px;
  overflow-y: auto;

  /* Custom List Group */
  .c-list-group {
    .list-group-item:first-child {
      border-radius: 0 !important;
    }

    .list-group-item:last-child {
      border-radius: 0 !important;
    }
  }

  /* Scrollbar */
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #b1b1b1;
  }
}
</style>

<style lang="scss">
.visions > .con-vs-card > .vs-card--content {
  background-color: #fafafa;
}

.visions .con-vs-chip .text-chip.vs-chip--text {
  padding: 3px 5px;
  white-space: nowrap;
}

.visions .vision-card > .vs-card--content {
  margin-bottom: 0;
}
</style>
